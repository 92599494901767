<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.version_code" @change="Search" placeholder="版本"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" >新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column label="所属产品型号">
        <template v-slot="scope">
          <span>{{ scope.row.product_model_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="包名称">
        <template v-slot="scope">
          <span>{{ scope.row.package_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="包大小">
        <template v-slot="scope">
          <span>{{ scope.row.package_size }}</span>
        </template>
      </el-table-column>
      <el-table-column label="版本号">
        <template v-slot="scope">
          <span>{{ scope.row.version_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="版本包在s3存储的key名称">
        <template v-slot="scope">
          <span>{{ scope.row.key }}</span>
        </template>
      </el-table-column>
      <el-table-column label="版本包md5信息">
        <template v-slot="scope">
          <span>{{ scope.row.md5 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="当前版本状态">
        <template v-slot="scope">
          <span>{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="版本包更新日志">
        <template v-slot="scope">
          <span>{{ scope.row.log }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template v-slot="scope">
          <router-link style="margin-right:10px" :to="'/iot/app/version/solid/update/'+scope.row.code">
            <el-button type="primary" size="small">修改</el-button>
          </router-link>
          <el-button type="danger" size="small" @click="deleteSolid(scope.row.code)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        product_model_code: '',
        version_code: '',
      },
      list: [],
      total: 0
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search() {
      this.api.FirmwareList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deleteSolid(item) {
      this.$confirm('是否删除该固件版本?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.FirmwareDelete({code: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    goback () {
      this.$router.push('/iot/app/appvesion')
    }
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
